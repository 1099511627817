h1,
.h1 {
  font-family: $font-family-primary;
  font-weight: 800;
  color: $brand-red;
  font-size: 4rem;
}

h2,
.h2 {
  font-family: $font-family-primary;
  font-weight: 800;
  color: $color-action-blue-700;
  font-size: 3.25rem;
}

h3,
.h3 {
  font-family: $font-family-primary;
  font-weight: 700;
  color: $brand-red;
  font-size: 1.5rem;
  text-transform: uppercase;
}

.emphasis {
  font-weight: 700;
  color: $color-action-blue-500;
}

p {
  font-family: $font-family-primary;
  font-weight: 400;
  font-size: 2rem;
  overflow-wrap: break-word;

  @media all and (max-width: $bp-large) {
    text-align: center;
  }
}

code,
samp,
pre {
  font-family: $font-family-mono;
}
