.rbt {
  color: $color-btn-outline;
  z-index: inherit;
}

.rbt-input-main {
  border: 1px solid $color-btn-outline;
  &::placeholder {
    color: $color-btn-outline;
  }
}

.rbt-menu {
  list-style: none;
  border-radius: $border-radius;
  box-shadow: 0 4px 8px 0 rgba(138, 148, 153, 0.2),
    0 6px 20px 0 rgba(138, 148, 153, 0.19);
  background-color: white;
  padding: 1.5rem 0;
  margin: 0;
  border: 1px solid $color-gray-200;
  z-index: 99999;
  max-height: 20rem !important;
}

.rbt-menu li {
  padding-left: 1rem;
  border-left: 3px solid transparent;
  &:hover {
    border-color: $color-btn-outline-bg-hover;
  }
  width: 100%;
  > a {
    display: block;
    width: 100%;
  }
}

.rbt-menu .dropdown-header {
  cursor: default;
  color: $brand-red;
  background: $color-gray-000;
  &:hover {
    border-color: transparent !important;
  }
}

.rbt-aux .rbt-close {
  margin-top: 0;
  background-color: white;
  border: 0;
  font-size: 2rem;
  color: $color-btn-outline;
  z-index: inherit;
}

.rbt-input-hint {
  top: 0.94rem !important;
  overflow: hidden;
  width: 100%;
}
.rbt-input-hint-container {
  &::after {
    position: absolute;
    grid-area: select;
    justify-self: end;
    content: '';
    width: 0.8em;
    height: 0.5em;
    background-color: #0088cc;
    -webkit-clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    top: 2rem;
    right: 2rem;
  }
}

.rbt-input-multi {
  max-width: 100%;
  .rbt-input-hint-container {
    &::after {
      display: none;
    }
  }

  .rbt-input-wrapper {
    margin: 1px 45px 1px 0px;
    max-height: 8rem;
    overflow: auto;
    background-color: white;
    border-radius: 1rem;
    padding: 0 0 0 7px;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
.rbt-input-multi.focus {
  box-shadow: none !important;
  border-color: none !important;
}
.rbt-input-multi.form-control[disabled] {
  background-color: transparent;
}

.rbt-token {
  background-color: #e7f4ff;
  border: 0;
  border-radius: 0.25rem;
  color: #007bff;
  display: inline-block;
  line-height: 1em;
  margin: 1px 3px 2px 0;
  padding: 4px 0px 4px 10px;
  position: relative;

  .rbt-token-remove-button {
    bottom: 0;
    background-color: transparent;
    border: none;
    color: inherit;
    font-size: inherit;
    font-weight: normal;
    opacity: 1;
    outline: none;
    padding: 3px 7px;
    position: relative;
    right: 0;
    left: 1px;
    text-shadow: none;
    top: -1px;
    height: 3px;
  }
}
.rbt-token-removeable {
  cursor: pointer;
}
